import './Partners.css';
import LinkButton from '../components/LinkButton/LinkButton'
import handshakeImg from '../assets/images/partners/yncn_handshake.png'
import { useEffect, useState } from 'react';
import StandardPartners from './StandardPartners';
import React from "react";
import Row from "react-bootstrap/Row";
import GoldPartners from "./GoldPartners";
import SilverPartners from "./SilverPartners"
import EventPartners from "./EventPartners"
import PopupCard from '../components/PopupCard';

export default function Partners() {
    let [eventPartners, setEventPartners] = useState([]);
    let [standardPartners, setStandardPartners] = useState([]);
    let [silverPartners, setSilverPartners] = useState([]);
    let [goldPartners, setGoldPartners] = useState([]);
    let [clickedPartner, setClickedPartner] = useState({});
    let [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = (partner) => {
        setClickedPartner(partner);
        setShowPopup(true);
    }
    const handleClosePopup = () => setShowPopup(false);

		useEffect(() => {
		fetch("/api/partners")
				.then(response => response.json())
				.then(data => {
						setEventPartners(data['event_partners']);
						setStandardPartners(data['standard_partners']);
						setSilverPartners(data['silver_partners']);
						setGoldPartners(data['gold_partners']);
				});
    }, []);

    return (
        <div>
            <PartnersHeader />
            <GoldPartnersSection goldPartners={goldPartners} handleShowPopup={handleShowPopup} />
            <SilverPartnersSection silverPartners={silverPartners} handleShowPopup={handleShowPopup} />
            <StandardPartnersSection standardPartners={standardPartners} handleShowPopup={handleShowPopup} />
            <EventPartnersSection eventPartners={eventPartners} handleShowPopup={handleShowPopup} />
            <BottomSection />
            <PopupCard show={showPopup} handleClose={handleClosePopup} partner={clickedPartner} />
        </div>
    );
}

const PartnersHeader = () => {
    return (
        <div className="partners-header">
            <h1>Our Partners</h1>
            <p className="partners-subtext">This year, we’re partnering with 100+ companies. Thank you to our sponsors for your generous support!</p>
        </div>
    )
}

const GoldPartnersSection = ({goldPartners, handleShowPopup}) => {
    return (
        <div className="partners-list gold-partners">
            <Row className="career-fair-content">
                <h3>Gold Career Fair Partners</h3>
                <GoldPartners goldPartners={goldPartners} handleShowPopup={handleShowPopup}/>
            </Row>
        </div>
    )
}

const SilverPartnersSection = ({silverPartners, handleShowPopup}) => {
    return (
        <div className="partners-list silver-partners">
            <Row className="career-fair-content">
                <h3>Silver Career Fair Partners</h3>
                <SilverPartners silverPartners={silverPartners} handleShowPopup={handleShowPopup}/>
            </Row>
        </div>
    )
}

const StandardPartnersSection = ({standardPartners, handleShowPopup}) => {
    return (
        <div className = "standard-partners-background">
            <StandardPartners partners={standardPartners} handleShowPopup={handleShowPopup}/>
        </div>
    )
}

const EventPartnersSection = ({eventPartners, handleShowPopup}) => {
	return(
		<div className="partners-list event-partners">
				<Row className="career-fair-content">
						<h3>Event Partners</h3>
						<EventPartners eventPartners={eventPartners} handleShowPopup={handleShowPopup}/>
				</Row>
		</div>
	)

}

const BottomSection = () => {
    return (
        <div className="goodbye">
            <h1>Interested in Partnering With Us?</h1>
            <div className="learn-more-button">
                <LinkButton text="Learn More" href="employers" />
            </div>
            <img src={handshakeImg} alt=""></img>
        </div>
    );
}
