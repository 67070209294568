import LinkButton from '../components/LinkButton/LinkButton'
import './Home.css';
import welcome_image from '../assets/images/home/home-illust.png';
import UpcomingEvents from './UpcomingEvents';
import FeaturedResources from './FeaturedResources';
import CarouselComponent from '../components/Carousel';
import React, { Component } from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function Home() {
    return (
        <div className="home">
            {/* <HeroSection /> */}
            <CarouselComponent />
            {/* <RegisterForCF/> */}
            <FeaturedResources />
            <UpcomingEvents />
            <TestimonialsSection />
            <RecruiterAndStudentActionsSection />
        </div>
    );
}

const RegisterForCF = () => {
    return (
        <Container style={{ "box-shadow": "0px 0px 10px 0px rgb(0 0 0 / 25%)", "borderRadius": "20px", "marginTop": "100px", "width": "80%" }}>
            <div className="career-fair-content">
                <Row style={{"paddingTop": "30px"}}>
                <Col sm={12} md={8}>
                    <div className="about-cf-box">
                    <h3>About the Career Fair</h3>
                    <p>
                        Looking to land your dream job? This year, meet top notch
                        employers and learn about cutting edge industries at YNCN’s in-person Winter Career Fair 2024!
                    </p>
                    <br />
                    <p>
                        Whether you’re a first year student looking for a{" "}
                        <b>summer internship</b>, a fourth year trying to land your
                        dream <b>full time job</b>, or hunting for a <b>PEY position</b>
                        , our career fair has a company for you. Check out the list of
                        attending companies to learn more about who’s right for you and
                        what they're looking for!
                    </p>
                    <br />
                    <p>
                        {/* Join us on Friday, February 3, 2023 from 10am to 4pm EST at the Carlu, */}
                        Join us February 2, 2024 at the Carlu,
                        and get ready to connect with many
                        employers spanning across a wide variety of industries who are
                        looking for students like you!
                    </p>
                    <br />
                    <p>
                        Fix up your resume and perfect your pitch, because You're Next!
                    </p>
                    <br></br>
                    </div>
                </Col>
                <Col sm={12} md={4} style={{"marginTop": "100px"}}>
                    <div className="details-box">
                    <div className="details-text">
                        <h3>Details</h3>
                        <p>
                        <i className="fa fa-clock"></i> Friday Feb 2, 2024
                        </p>
                        <p>
                        <i className="fa fa-map-marker-alt"></i> The Carlu - 444 Yonge St #7
                        </p>
                    </div>
                    <div className="details-buttons">
                        <a
                        className="link-button"
                        href="https://2024wintercareerfair.splashthat.com/"
                        style={{"cursor": "pointer"}}
                        >
                        Register Now
                        </a>
                    </div>
                    </div>
                </Col>
                </Row>
            </div>
        </Container>
    )
}

const HeroSection = () => {
    return (
        <section className="hero row">
            <div className="base-gradient"></div>
            <div className="hero-text">
                <h3>You're Next Career Network</h3>
                <h1>Connecting students with opportunities</h1>
                <p className="subtext">We are a University of Toronto student club dedicated to providing students with professional development opportunities and connecting companies with top talent. </p>
                <LinkButton text="Learn More" href="about" />
            </div>
            <div className="illustrations">
                <img id="home-ill" src={welcome_image} alt="illustration" />
            </div>
        </section>
    )
}

function getYearsSinceEst() {
    let date1 = new Date(2009, 9, 1);
    let date2 = new Date();
    let yearsDiff =  date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
}

const TestimonialsSection = () => {
    return (
        <section className="yellow-background">
            <h1>Testimonials</h1>
            <h3>{getYearsSinceEst()} years of experience connecting students with their dream opportunities</h3>
            <p>Every year, YNCN helps 6,000+ students connect with 100+ employers through our Fall and Winter Career Fairs, Company Spotlight Events, and Student Development Workshops.</p>
            <div className="drop-shadow-box">
                <p>“Partnering with YNCN has been great over the past 2 years! From technical interviews to resume reviews - we've hosted several events together serving students who are looking to develop their technical skills to be better prepared for an internship or full-time opportunity at Google. Each event is always well staffed, well organized and well attended. Thanks to all the execs who do an amazing job executing every single event; they wouldn't be successful without you!”</p>
                <h3>- Akailah Wilson, Google</h3>
            </div>
        </section>
    )
}

const RecruiterAndStudentActionsSection = () => {
    return (
        <section>
            <div className="recruiter-student">
                <div className="recruiter-box">
                    <div className="inside-container1 border-right">
                        <h1>Are you a recruiter?</h1>
                        <p>Hire the best talent at the University of Toronto by getting a spot at our career fair or by partnering with us for an event.</p>
                        <LinkButton text="Learn More" href="/employers" />
                    </div>
                </div>
                <div className="student-box">
                    <div className="inside-container2">
                        <h1>Are you a student?</h1>
                        <p>Let's stay in touch! Sign up for our newsletter to stay updated with our events, resources and exclusive oppourtunities!</p>
                        <LinkButton text="Sign up for Newsletter" href="https://madmimi.com/signups/a64c937ab6c94b2797d1d464f9592437/join" />
                    </div>
                </div>
            </div>
        </section>
    )
}
