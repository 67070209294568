import React from "react";
import Col from "react-bootstrap/Col";
import "./LogoDisplay.css";

const logoItem = ({item, tier, handleShowPopup}) => {
  if (tier === 'Gold' || tier === 'Event') {
    return (
      <Col sm={3} className="cf-img-container">
        <a onClick={() => handleShowPopup(item)}>
            <img
              className="cf-img gold-logo"
              src={item.logo}
              alt={""}
            />
            <span className="tooltiptext">{item.name}</span>
        </a>
      </Col>
    );
  }
  else if (tier === 'Silver') {
    return (
      <Col sm={3} lg={2} className="cf-img-container">
        <a onClick={() => handleShowPopup(item)}>
            <img
              className="cf-img silver-logo"
              src={item.logo}
              alt={""}
            />
            <span className="tooltiptext">{item.name}</span>
        </a>
      </Col>
  );
  }
}
export default logoItem;